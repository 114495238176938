import React, { useEffect, useMemo, useRef, useState } from "react"
import { useQuery } from "react-query"
import { getActivities, getWidgetById } from "backend/api/activities"
import { Order } from "types/order.type"
import DateTicketPicker from "widget/DateTicketPicker"
import ActivityList from "widget/ActivitiyList"
import Payment from "widget/Payment"
import { getQueryParams, googleFontLoader } from "helpers/helpers"
import LanguageSelector from "components/general/LanguageSelector"
import { stripeConnectVerification } from "backend/api/stripeConnect"
import Skeletons from "components/general/Skeletons"
import { useTranslation } from "react-i18next"
import { isEmpty, isNumber, isString } from "lodash"
import { WidgetContainer } from "./style"
import { WidgetType } from "types/widget.type"
import { useDispatch } from "react-redux"
import { WIDGET_UPLOAD_SUCCESS } from "constants/widgetConstants"
import { useSelector } from "react-redux"
import { RootState } from "store"

export type Step = "activityList" | "dateTicketPicker" | "payment"

const Widget = () => {
  const { t } = useTranslation("general")
  const urlParams = getQueryParams()
  const widgetIdParam: string | null = urlParams.get("widgetId")
  const [CLIENT_ID, setCLIENT_ID] = useState(urlParams.get("clientId") || "")
  const [currentStep, setCurrentStep] = useState<Step>("activityList")
  const [order, setOrder] = useState<Order>({
    selected_activities: [],
    bookings: [],
  })
  const widget = useSelector((state: RootState) => state.widget) as WidgetType
  const dispatch = useDispatch()
  const widgetRef = useRef(null)

  const widgetId = isString(widgetIdParam) ? parseInt(widgetIdParam) : null

  if (isNumber(widgetId)) {
    useQuery({
      queryKey: [`get-widget-${widgetId}`],
      queryFn: () => getWidgetById(widgetId),
      staleTime: 60000,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess(data) {
        dispatch({ type: WIDGET_UPLOAD_SUCCESS, payload: data })
        setCLIENT_ID(String(data.clientId))
      },
    })
  }
  const handleScrollToWidget = () => {
    const widgetElement = widgetRef.current
    if (widgetElement) {
      // @ts-expect-error igonore typescript errors
      const widgetTop = widgetElement.getBoundingClientRect().top
      if (widgetTop > 50) {
        // Scroll if y postition > 50x diff
        // @ts-expect-error igonore typescript errors
        widgetElement.scrollIntoView({ behavior: "smooth" })
      }
    }
  }

  const { data: activities, isLoading: isActivitiesLoading } = useQuery({
    queryKey: [`get-all-actitivities-${CLIENT_ID}`],
    enabled: !!CLIENT_ID, // Only run query if CLIENT_ID is not an empty string
    queryFn: () => getActivities(CLIENT_ID),
    staleTime: 60000,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const openedActivities = useMemo(() => {
    const filteredActivities = isEmpty(widget)
      ? activities
      : activities?.filter((activity) => {
          return widget.activitiesIds?.some((activityId: number) => activityId === activity.id)
        })
    return filteredActivities?.filter((activity) => {
      const widgetChannel = activity.channels.find((channel) => channel.name === "widget")
      return widgetChannel?.status === "OPENED"
    })
  }, [activities, widget])

  useEffect(() => {
    const savedFontFamily = widget?.style?.fontFamily
    if (savedFontFamily) {
      googleFontLoader(savedFontFamily)
    }
  }, [widget])

  const { data: stripeVerification, isLoading: isStripeVerificationLoading } = useQuery({
    queryKey: [`strip-verification-${CLIENT_ID}`],
    enabled: !!CLIENT_ID,
    queryFn: () => stripeConnectVerification(CLIENT_ID),
    refetchOnWindowFocus: false,
    retry: false,
  })

  const handleChangeStep = (step: Step) => {
    const handleBackFromPayment = () => {
      setOrder((prevOrder) => {
        return { ...prevOrder, bookings: [] }
      })
    }
    setCurrentStep(step)
    if (step === "dateTicketPicker" || step === "activityList") {
      handleBackFromPayment()
    }
  }

  if (isActivitiesLoading || isStripeVerificationLoading) {
    return (
      <WidgetContainer ref={widgetRef}>
        <Skeletons />
      </WidgetContainer>
    )
  }

  if (!stripeVerification?.accountCompleted || !openedActivities) {
    return (
      <WidgetContainer ref={widgetRef}>
        <h1>{t("badAccountConfig")}</h1>
      </WidgetContainer>
    )
  }

  return (
    <WidgetContainer
      id="hopleisure-widget"
      ref={widgetRef}
      onClick={handleScrollToWidget}
      fontFamily={widget?.style?.fontFamily}
      style={{
        backgroundColor: widget?.style?.colors?.widgetBackgroundColor,
        color: widget?.style?.colors?.textColorOnBackground,
      }}
    >
      <LanguageSelector />
      {currentStep === "activityList" && (
        <ActivityList
          activities={openedActivities}
          order={order}
          setOrder={setOrder}
          handleChangeStep={handleChangeStep}
        />
      )}
      {currentStep === "dateTicketPicker" && order.selected_activities?.length > 0 && (
        <DateTicketPicker
          selectedActivies={order.selected_activities}
          handleChangeStep={handleChangeStep}
          order={order}
          setOrder={setOrder}
        />
      )}
      {currentStep === "payment" && (
        <Payment
          order={order}
          activity={order.selected_activities[0]}
          stripeAccountId={stripeVerification.accountId}
          handleChangeStep={handleChangeStep}
        />
      )}
    </WidgetContainer>
  )
}

export default Widget
