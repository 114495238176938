import { widgetReducer } from "reducers/widgetReducer"
import { createStore, combineReducers } from "redux"
import { composeWithDevTools } from "@redux-devtools/extension"

const reducer = combineReducers({
  widget: widgetReducer,
})
const initialState = {}
const store = createStore(reducer, initialState, composeWithDevTools())

export default store

export type RootState = ReturnType<typeof reducer>
