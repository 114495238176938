import { theme } from "helpers/constants"
import styled from "styled-components"

import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined"
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined"

interface ActivityCardContainerProps {
  activitycardbackgroundcolor?: string
  activitycardtextcolor?: string
}

export const ActivityCardContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !["activitycardbackgroundcolor", "activitycardtextcolor"].includes(prop),
})<ActivityCardContainerProps>`
  display: flex;
  flex-direction: row;
  wrap: flex-wrap;
  width: 100%;
  border: 0.5px solid #c1c1c1;
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;

  &,
  * {
    background-color: ${(props) => props.activitycardbackgroundcolor};
    color: ${(props) => props.activitycardtextcolor};
  }

  ${theme.mediaQueries.mobile} {
    flex-direction: column;
  }
`
interface ActivityCardButtonContainerrProps {
  actionbtntextcolor?: string
  actionbtnbackgroundcolor?: string
}

export const ActivityCardButtonContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !["actionbtnbackgroundcolor", "actionbtntextcolor"].includes(prop),
})<ActivityCardButtonContainerrProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  top: 25px;
  right: 25px;

  &,
  * {
    background-color: ${(props) => props.actionbtnbackgroundcolor};
    color: ${(props) => props.actionbtntextcolor};
  }

  ${theme.mediaQueries.mobile} {
    position: relative;
    margin-bottom: 30px;
    justify-content: flex-start;
    align-items: flex-start;
    right: auto;
    margin-left: 10px;
  }
`

export const ImageContainer = styled.div`
  flex: 0 0 175px;
  width: 170px;
  max-width: 100%;
  background: url(the_fratellis.jpg);
  background-size: cover;
  border-radius: 10px;
  margin-right: 15px;

  ${theme.mediaQueries.mobile} {
    width: 100%;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: calc(100% - 400px);

  ${theme.mediaQueries.mobile} {
    width: 100%;
  }
`

export const HeartIcon = styled(FavoriteBorderOutlinedIcon)`
  width: 25px;
  height: 25px;
  margin-right: 5px;
  color: ${theme.color.primary};
`

export const HeartIconSelected = styled(FavoriteOutlinedIcon)`
  width: 25px;
  height: 25px;
  margin-right: 5px;
  color: white;
`
